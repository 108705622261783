"use client";
import React, { useEffect, useState } from "react";
import styles from "./signup.module.scss";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CompleteAuthOTP } from "@services/newLogin";
import Cookies from "js-cookie";
import { sendGTMEvent } from "@next/third-parties/google";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useLocation } from "@hooks/useLocation";
import { getNames } from "country-list";

export default function Signup({
  data,
  dataVerify,
  handleRetrieveData,
  isRoute,
}) {
  // console.log(dataVerify, data);
  const { location, countryCode } = useLocation();
  console.log(location, countryCode);

  const isPhoneMethod = dataVerify.verifiedMethod === "PHONE";
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(false);
 console.log(location);
 const countries = getNames();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: isPhoneMethod ? dataVerify.verifiedMethodValue || "" : "",
    email: !isPhoneMethod ? dataVerify.verifiedMethodValue || "" : "",
    dateOfBirth: "",
    gender: "",
    termsAccepted: false,
    requestID: data.requestID,
    source: "tickit.co",
    residence: location ? location : "",
  });
useEffect (() => {
 if(location) setFormData((prev) => {
  return {...prev, residence: location}
 })
}, [location])
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
    }));
  };

  const isPhoneValid = !isPhoneMethod
    ? formData.phoneNumber && isValidPhoneNumber(formData.phoneNumber)
    : true;

  const isFormValid =
    formData.firstName &&
    formData.lastName &&
    isPhoneValid &&
    formData.dateOfBirth &&
    formData.gender &&
    formData.termsAccepted;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let today = new Date();
    let birthDate = new Date(formData.dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDiff = today.getMonth() - birthDate.getMonth();
    let dayDiff = today.getDate() - birthDate.getDate();
    
    // Adjust age calculation if birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    // Check age restriction
    if (age < 14) {
      setError("You must be at least 14 years old to sign up.");
      return;
    }
  

    if (isFormValid) {
      const serviceRequest = {
        requestID: formData.requestID,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email || "",
        phoneNumber: formData.phoneNumber || "",
        gender: formData.gender,
        dob: formData.dateOfBirth,
        source: formData.source,
        residence: formData.residence,
      };

      console.log("Form Submitted:", serviceRequest);
      setLoading(true);
      try {
        const { status, data } = await CompleteAuthOTP(serviceRequest);
        console.log(status, data);
        if (status === 200) {
          const userData = data.data.userData;
          const token = data.data.token;
          const updatedData = { ...data.data, nextAction: "CONTINUE" };
          handleRetrieveData(updatedData);
          // Cookies.set(
          //   "user",
          //   JSON.stringify({
          //     id: userData.uid,
          //     email: userData.email,
          //     idToken: userData.idToken?? null,
          //     refreshToken: userData.refreshToken?? null,
          //     fname: userData.firstName,
          //     lastName: userData.lastName,
          //     country: userData.address,
          //     phoneNumber: userData.phoneNumber,
          //     gender: userData.gender,
          //     dob: userData.dob,
          //     updatedFields: userData.updatedFields,
          //     username: userData.userName,
          //     isPhoneVerified: userData.isPhoneVerified,
          //     isEmailVerified: userData.isEmailVerified,
          //   })

          // );
          // sendGTMEvent({ event: 'Sign In', value: 'Email' })

          // Cookies.set(
          //   "tokens",
          //   JSON.stringify({
          //     idToken: token,
          //     refreshToken: "",
          //   })
          // );
        }
      } catch (error) {
        console.error(error);
        setError(error.response.data.message);
      }

      setLoading(false);
      // Perform the submit action (e.g., API call)
    }
  };

  useEffect(() => {
    if (!formData.dateOfBirth) {
      const today = new Date();
      const defaultDate = new Date();
      defaultDate.setFullYear(today.getFullYear() - 14); // Set the default date to 14 years ago
      const formattedDate = defaultDate.toISOString().split("T")[0]; // 'YYYY-MM-DD'
      setFormData((prevData) => ({
        ...prevData,
        dateOfBirth: formattedDate,
      }));
    }
  }, [formData.dateOfBirth]);
  
  return (
    <>
      <div className={styles.overlay}></div>
      <div
        className={
          isRoute
            ? `${styles.container} ${styles.route}`
            : `${styles.container}`
        }
      >
        <form className={styles.parent} onSubmit={handleSubmit}>
          <div className={styles.div1}>
            <p>👋 Let’s get to know each other</p>
          </div>
          <div className={styles.div2}>
            <input
              type="text"
              name="firstName"
              placeholder="First name"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.div3}>
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.div4}>
            {!isPhoneMethod ? (
              <div className="signUpPhone">
                <div className={styles.phone_input}>
                  <PhoneInput
                    international
                    defaultCountry={countryCode ?? ""}
                    value={formData.phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            ) : (
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            )}
          </div>
          <div className={styles.div5}>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              placeholder="dd / mm / yyyy"
              size={100}
            />
          </div>
          <div className={styles.div6}>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Others</option>
            </select>
          </div>

          <div className={styles.div9}>
            <select
              name="residence"
              value={formData.residence}
              onChange={handleChange}
            >
                {Object.values(countries).map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
            </select>
          </div>

          <div className={styles.div7}>
            <input
              type="checkbox"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
            />
            <p>
              By creating an account, I agree to our <span>Terms of Use</span>{" "}
              and <span>Privacy Policy</span>
            </p>
          </div>
          <div className={styles.div8}>
            {error.length > 0 && (
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  lineHeight: "normal",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <IoAlertCircleOutline fill="red" style={{ fontSize: "14px" }} />{" "}
                {error}
              </div>
            )}

            <div className={styles.submit}>
              <button type="submit" disabled={!isFormValid || Loading}>
                {Loading ? "Loading..." : "Continue"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
