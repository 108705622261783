import React, { useEffect, useRef, useState } from "react";
import styles from "./verificationCode.module.scss";
import { ResendAuthOTP, ValidateAuthOTP } from "@services/newLogin";
import ResendWays from "../chooseResend/chooseResend";
import { IoAlertCircleOutline } from "react-icons/io5";

function SixDigitInput({ onComplete, onChange }) {
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (!/^[0-9]?$/.test(value)) return;

    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    onChange(newDigits.join(""));

    if (value) {
      if (index < 5) {
        inputsRef.current[index + 1].focus();
      } else {
        onComplete();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !digits[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").trim();
    if (!/^\d+$/.test(pasteData)) return;

    const pasteDigits = pasteData.split("").slice(0, 6 - index);
    const newDigits = [...digits];

    pasteDigits.forEach((digit, idx) => {
      newDigits[index + idx] = digit;
    });

    setDigits(newDigits);
    onChange(newDigits.join(""));

    const nextIndex = index + pasteDigits.length;
    if (nextIndex < 6) {
      inputsRef.current[nextIndex].focus();
    } else {
      onComplete();
    }
  };

  return (
    <div className={styles.containerDigits}>
      {digits.map((digit, index) => (
        <input
          key={index}
          // type="text"
          maxLength="1"
          type="tel" // Ensures numeric keypad opens on mobile
          inputMode="numeric" // Explicitly requests the numeric keyboard
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          ref={(el) => (inputsRef.current[index] = el)}
          className={styles.digitBox}
        />
      ))}
    </div>
  );
}

export default function VerificationCode({ data, handleRetrieveData,handleResendBack, startingAccount, isRoute }) {
  const [code, setCode] = useState("");
  const [openResend, setOpenResend] = useState(false);
  const confirmButtonRef = useRef(null);
  const [countdown, setCountdown] = useState(data.resendInMinutes * 60);
  const [Loading, setLoading]= useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleComplete = () => {
    confirmButtonRef.current.focus();
  };

  const handleSubmit = async () => {
    console.log("Submitted Code:", code);
    setLoading(true);
    try {
      const { status, data: validateData } = await ValidateAuthOTP(
        data.requestID,
        code
      );
      if (status === 200) {
        console.log(validateData);
        handleRetrieveData(validateData.data);
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
      setError(error.response.data.message);
    }
    setLoading(false);
  };
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins > 0 ? `${mins} minute${mins > 1 ? 's' : ''} ` : ''}${
      secs > 0 ? `${secs} second${secs !== 1 ? 's' : ''}` : ''
    }`;
  }
  const handleResend = async () => {
    if (countdown > 0) return; // Prevent resending if countdown is active
    setOpenResend(true)

  };
  const SuccessResend = (time) => {
    setCountdown(time * 60);
    setOpenResend(false);
  }
  const way = data.method === "PHONE" ? "phone number" : "email"
  return (
    <>
      <div className={styles.overlay}></div>
      <div className={isRoute? `${styles.container} ${styles.route}` : `${styles.container}`}>
        <div className={styles.header}>
          <div className={styles.title}>Verification Code</div>
          <div className={styles.desc}>
            We have sent the verification code <br /> to <mark style={{backgroundColor:"white"}}>{data.to}</mark>
          </div>
        </div>

        <SixDigitInput onComplete={handleComplete} onChange={setCode} />
        {error.length>0 && <div style={{color:"red", fontSize:"12px", lineHeight:"normal", marginBottom:"10px", display:"flex", alignItems:"center", gap:"5px"}}><IoAlertCircleOutline fill="red" style={{fontSize:"14px"}}/>{" "}{error}</div>}

        <div className={styles.submit}>
          <button ref={confirmButtonRef} disabled={Loading} onClick={handleSubmit}>
            {Loading? "Confirming..." : "Confirm"}
          </button>
        </div>

        <div className={styles.verifyInfo}>
          {countdown > 0 ? (
            <p className={styles.note}>
              Available again in {formatTime(countdown)}
            </p>
          ) : (
            <p className={styles.note}>
            {/* Available again in {countdown} */}
          </p>
          )}
          <p className={styles.resend}>
            Didn’t receive a code?{" "}
            <span
              className={
                countdown > 0 ? styles.disabledResend : styles.activeResend
              }
              onClick={countdown === 0 ? handleResend : null}
              aria-disabled={countdown > 0}
              tabIndex={countdown > 0 ? -1 : 0}
            >
              Re-send
            </span>
          </p>
          {countdown > 0 &&    <p className={styles.resend} 
          onClick={()=>{
            if(data.method === 'PHONE') handleResendBack(true)
            else handleResendBack(false);
            }}
          >
          
         <span className={styles.activeResend}>Try with {way} instead</span> 
           
          </p>}
        </div>
      </div>

      {openResend && <ResendWays data={data} handleRetrieveData={handleRetrieveData} Loading={Loading} setLoading={setLoading} handleResendBack={handleResendBack} setOpenResend={setOpenResend} SuccessResend={SuccessResend} startingAccount={startingAccount} isRoute={isRoute}/>}
    </>
  );
}