import config from "@config/config";
import { firebaseInstance } from "./api";
import axios from "axios";

export const RequestAuthOTP = async ( method,
    to,
    selectedPhoneMethod,) => {
    const source = "tickit.co";
    try {
      const response = await firebaseInstance.post("api/auth/request-auth-otp", {
        method,
        to,
        selectedPhoneMethod,
        source,
      });
  
      return response;
    } catch (error) {
      console.error("Error during request otp: ", error);
      throw error;
    }
  };



export const ValidateAuthOTP = async (     requestID,
    otp) => {
    const source = "tickit.co";
    try {
      const response = await firebaseInstance.post("api/auth/validate-auth-otp", {
        requestID,
        otp,
        source
      });
  
      return response;
    } catch (error) {
      console.error("Error during validate otp: ", error);
      throw error;
    }
  };



export const ResendAuthOTP = async ( requestID,
     method,
  selectedPhoneMethod,
  ) => {
    const source = "tickit.co";
    try {
      const response = await firebaseInstance.post("api/auth/resend-auth-otp", {
        requestID,
        method,
        selectedPhoneMethod,
        source,
      });
  
      return response;
    } catch (error) {
      console.error("Error during resend otp: ", error);
      throw error;
    }
  };

export const CompleteAuthOTP = async ( data
  ) => {
    try {
      const response = await firebaseInstance.post("api/auth/complete-auth-profile", data);
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };

export const GoogleAuth = async ( code
  ) => {
    const source = "tickit.co";
    try {
      const response = await firebaseInstance.post("api/auth/request-google-auth", {
        code,
        source
      });
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };

export const AppleAuth = async ( grantCode,
  redirectUri
  ) => {
    const source = "tickit.co";
    try {
      const response = await firebaseInstance.post("api/auth/request-apple-auth", {
        grantCode,
        source,
        redirectUri
      });
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };

export const logout = async ( token
  ) => {
    const firebaseUserInstance = axios.create({
      baseURL: config.FIREBASE_WS_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    try {
      const response = await firebaseUserInstance.post("api/auth/logout",);
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };

export const RequestVerification = async ( data, token
  ) => {
    const firebaseUserInstance = axios.create({
      baseURL: config.FIREBASE_WS_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    try {
      const response = await firebaseUserInstance.post("api/verification-codes/request-verification-code",data);
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };


export const ValidateVerification = async ( data, token
  ) => {
    const firebaseUserInstance = axios.create({
      baseURL: config.FIREBASE_WS_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    try {
      const response = await firebaseUserInstance.post("api/verification-codes/validate-verification-code",data);
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };

export const ResendVerification = async ( data, token
  ) => {
    const firebaseUserInstance = axios.create({
      baseURL: config.FIREBASE_WS_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    try {
      const response = await firebaseUserInstance.post("/api/verification-codes/resend-verification-code",data);
  
      return response;
    } catch (error) {
      console.error("Error during submit data: ", error);
      throw error;
    }
  };


