"use client";

import { useState, useEffect } from "react";

export const useLocation = () => {
  const [location, setLocation] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSavedLocation = async () => {
      const savedLocation = localStorage.getItem("userLocation");
      if (savedLocation) {
        try {
          console.log(savedLocation);
          const { country, code } = await getCountryFromCoordinates(JSON.parse(savedLocation));
          setLocation(country);
          setCountryCode(code);
        } catch (error) {
          console.error("Error fetching country from saved location:", error);
        } finally {
          setLoading(false);
        }
      } else {
        askForLocation();
      }
    };
    fetchSavedLocation();
  }, []);

  const askForLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const { country, code } = await getCountryFromCoordinates(position);
          setLocation(country);
          setCountryCode(code);
          localStorage.setItem("userLocation", JSON.stringify(position));
        } catch (error) {
          console.error("Error fetching country from current position:", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  async function getCountryFromCoordinates(data) {
    const { latitude, longitude } = data.coords;

    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const result = await response.json();

      // Extract the country and country code from the response
      const country = result?.address?.country;
      const code = result?.address?.country_code?.toUpperCase();
      if (!country || !code) {
        throw new Error("Country information not found in API response");
      }

      return { country, code };
    } catch (error) {
      console.error("Error fetching country data:", error);
      throw error;
    }
  }

  return { location, countryCode, loading, askForLocation };
};