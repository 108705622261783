import React from 'react';

const SmsIcon = () => (
  <svg
    clipRule="evenodd"
    fillRule="evenodd"
    height="20px"
    width="20px"
    viewBox="0 0 2048 2048"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path d="M0 0h2048v2048H0z" fill="#4bed3e" />
    <g>
      <path
        fill="#fffffe"
        fillRule="nonzero"
        d="M582.706 1317.4c121.885 56.0174 269.149 89.869 427.398 91.7387l63.2351-2.49567-122.426 227.215 
        346.154-254.781 12.2492-5.77205c84.9331-24.2351 157.452-50.1036 233.752-95.6469 95.8595-57.2174 166.901-128.83 
        208.099-207.148 40.0429-76.1221 51.7252-158.895 30.1878-240.842-8.46024-32.1862-21.9886-64.3158-40.865-95.941-76.8355-128.727-226.855-225.129-407.242-276.908-183.053-52.5461-396.238-58.7847-596.106-6.25158-88.128 
        23.1626-166.061 55.7233-232.21 95.2076-95.8595 57.2162-166.901 128.83-208.099 207.148-40.0441 76.1221-51.7264 158.895-30.1878 240.841 
        8.46024 32.1874 21.9874 64.317 40.8638 95.941 54.5682 91.4198 151.485 170.836 275.197 227.695z"
      />
    </g>
  </svg>
);

export default SmsIcon;
