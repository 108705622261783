import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export const useUser = () => {
  const [user, setUser] = useState({});
  const [tokens, setTokens] = useState({});
  const [token, setNewToken] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const refresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    const fetchedUser = JSON.parse(Cookies.get("user") || "{}");
    const fetchedTokens = JSON.parse(Cookies.get("tokens") || "{}");
    const fetchedToken = JSON.parse(Cookies.get("token") || null);
    setUser(fetchedUser);
    setTokens(fetchedTokens);
    setNewToken(fetchedToken);
  }, [refreshKey]);

  const setToken = (newTokens) => {
    Cookies.set("tokens", JSON.stringify(newTokens));
  };

  return { user, tokens,token, setToken, refresh };
};
