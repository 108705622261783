"use client";

import React, { useState } from "react";
import styles from "./ByPhone.module.scss";
import { FcGoogle } from "react-icons/fc";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { GoogleAuth, RequestAuthOTP } from "@services/newLogin";
import { IoAlertCircleOutline } from "react-icons/io5";
import AppleSignInButton from "../appleSignIn/appleSignIn";
import { useGoogleLogin,  } from '@react-oauth/google'; // Import useGoogleLogin

export default function LoginByPhone({handleRetrieveData, setStartingAccount, location, countryCode}) {
  const [phone, setPhone] = useState("");
  const [Loading, setLoading]= useState(false);
  const [error, setError] = useState("");
  const [LoadingGoogle, setLoadingGoogle] = useState(false);
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const dummy = {
    "nextAction": "SELECT-PHONE-METHOD",
    "method": "PHONE",
    "to": "+961 71159945",
    "userData": null,
    "availablePhoneMethods": {
        "SMS": true,
        "WHATSAPP": true
    },
    "requestID": null
};
  const isPhoneValid = phone && isValidPhoneNumber(phone);

  const handleSubmit = async () => {
    setLoading(true);    
    // handleRetrieveData(dummy)

    try {
      const {status, data} = await RequestAuthOTP ("PHONE", phone, null);
      console.log(data, status === 200);

      if(status === 200)
      {
        console.log(data.data);
        // setData(data.data);
        // console.log(data.data);
        setStartingAccount(phone);
        handleRetrieveData(data.data);
 
        
      }
      // console.log(res);
      
    } catch (error) {
      setError(error.response.data.message);
    }
    setLoading(false);
  }

    // Google Sign-In Success Handler
    const handleGoogleSuccess = async (resData) => {
      console.log(resData);
      setLoadingGoogle(true);
       try {
          const {status, data} = await GoogleAuth(resData.code);
          console.log(status, data);
          if(status === 200)
            handleRetrieveData(data.data, true);
          
       } catch (error) {
        console.log(error);
        
       }
       setLoadingGoogle(false);
      };
    
      // Google Sign-In Error Handler
      const handleGoogleError = (error) => {
        console.error('Google Sign-In Error:', error);
        setError('Google Sign-In failed. Please try again.');
      };
    
      // Initialize Google Login
      const login = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleError,
        flow: 'auth-code',
        scope: 'openid email profile',
      });
      console.log(location, countryCode);
      
  return (
    <>
      <div className={styles.input}>
        <div className={styles.phone_input}>
          <PhoneInput
            international
            defaultCountry={location? countryCode : ""}
            onChange={handlePhoneChange}
            value={phone}
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div className={styles.submit}>
        <button disabled={!isPhoneValid || Loading} onClick={handleSubmit}>
          {Loading ? "Sending OTP..." : "Send OTP"}
        </button>
      </div>
      {error.length>0 && <div style={{color:"red", fontSize:"12px", lineHeight:"normal", marginTop:"10px", display:"flex", alignItems:"center", gap:"5px"}}><IoAlertCircleOutline fill="red" style={{fontSize:"14px"}}/>{" "}{error}</div>}
      <div className={styles.otherWay}>
        <span></span>
        <p>Or log in with</p> <span></span>
      </div>
      <div className={styles.providedWays}>
        <AppleSignInButton  handleRetrieveData={handleRetrieveData} LoadingGoogle={LoadingGoogle} setLoadingGoogle={setLoadingGoogle}/>
        <button onClick={() => login()} disabled={LoadingGoogle}>
        {LoadingGoogle ?  <div className={styles.loader}></div>: <FcGoogle />}
        </button>
      </div>
    </>
  );
}
