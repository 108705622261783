"use client";

import React, { useState } from "react";
import styles from "./verificationWay.module.scss";
import { RequestAuthOTP, RequestVerification } from "@services/newLogin";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useRouter } from "next/navigation";

function removeSpaces(str) {
  return str.replace(/\s+/g, "");
}

export default function VerificationWay({
  phone,
  handleRetrieveData,
  receivedData,
  isRoute = false,
  isRequest = false,
  handleRequest,
  eventID="",

  utm_source="",
  setSlectedPhoneMethod,
}) {
  const [error, setError] = useState("");
const router = useRouter();
  const [loading, setLoading] = useState(false);
  const dummy = {
    nextAction: "VALIDATE-OTP",
    method: "PHONE",
    to: "+961 71******",
    userData: {
      firstName: "fathi",
      lastName: "sulieman",
    },
    availablePhoneMethods: {
      SMS: true,
      WHATSAPP: true,
    },
    requestID: "MM0e7rz4Xe9gyOe0UxFp",
    resendInMinutes: 2,
    expireInMinutes: 2,
  };
  const handleSubmit = async (way) => {
    console.log("Sas", phone);

    setLoading(true);
    // handleRetrieveData(dummy)
    try {
      const { status, data } = await RequestAuthOTP(
        receivedData.method,
        removeSpaces(phone),
        way
      );

      if (status === 200) {
        handleRetrieveData(data.data); // Pass data to parent component
        console.log("API response data:", data);
      } else {
        console.error("API call was not successful:", status);
      }
    } catch (error) {
      console.error("Error occurred while calling the API:", error);
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitRequest = async (way) => {
    console.log("Sas", phone);

    setLoading(true);
    // handleRetrieveData(dummy)
    setSlectedPhoneMethod(way);
    try {
      const { status, data } = await RequestVerification(
        {
          method: "PHONE",
          to: removeSpaces(phone.phoneNumber),
          selectedPhoneMethod: way,
          source: "tickit.co",
        },
        phone.idToken
      );
      if (status === 200) {
        console.log(data.data);
        
        handleRequest(data.data);
      } else {
        console.error("API call was not successful:", status);
      }
    } catch (error) {
      if(error.response.status === 401) {
        utm_source
        ? router.push(`/login/${eventID}?utm_source=${utm_source}`)
        : router.push(`/login/${eventID}`);
      }
      else setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div
        className={
          isRoute
            ? `${styles.container} ${styles.route}`
            : `${styles.container}`
        }
      >
        <div className={styles.header}>
          <div className={styles.title}>Verification Code</div>
          <div className={styles.desc}>
            Do you want us to send you the <br /> verification code on
          </div>
        </div>

        <div className={styles.submit}>
          <button
            disabled={loading}
            onClick={() => {
              isRequest
                ? handleSubmitRequest("WHATSAPP")
                : handleSubmit("WHATSAPP");
            }}
          >
            <svg
              version="1.1"
              id="fi_733585"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512", width: "20px" }}
            >
              <path
                style={{ fill: "#4CAF50" }}
                d="M256.064,0h-0.128l0,0C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104 l98.4-31.456C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z"
              ></path>
              <path
                style={{ fill: "#FAFAFA" }}
                d="M405.024,361.504c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264 C189.888,347.2,141.44,270.752,137.664,265.792c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304 c6.176-6.304,16.384-9.184,26.176-9.184c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64 c6.176,14.88,21.216,51.616,23.008,55.392c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744 c-3.776,4.352-7.36,7.68-11.136,12.352c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616 c28.576,25.44,51.744,33.568,60.032,37.024c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496 c5.12-7.232,11.584-8.128,18.368-5.568c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736 C411.2,329.152,411.2,344.032,405.024,361.504z"
              ></path>
            </svg>{" "}
            Whatsapp
          </button>
        </div>
        <div className={styles.submit}>
          <button disabled={loading} onClick={() =>{        isRequest
                ? handleSubmitRequest("SMS")
                : handleSubmit("SMS");}}>
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              height="2048"
              imageRendering="optimizeQuality"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              viewBox="0 0 2048 2048"
              width="2048"
              id="fi_14817175"
              style={{ width: "20px", height: "20px" }}
            >
              <path d="m0 0h2048v2048h-2048z" fill="#4bed3e"></path>
              <g id="Layer_x0020_1">
                <path
                  d="m582.706 1317.4c121.885 56.0174 269.149 89.869 427.398 91.7387l63.2351-2.49567-122.426 227.215 346.154-254.781 12.2492-5.77205c84.9331-24.2351 157.452-50.1036 233.752-95.6469 95.8595-57.2174 166.901-128.83 208.099-207.148 40.0429-76.1221 51.7252-158.895 30.1878-240.842-8.46024-32.1862-21.9886-64.3158-40.865-95.941-76.8355-128.727-226.855-225.129-407.242-276.908-183.053-52.5461-396.238-58.7847-596.106-6.25158-88.128 23.1626-166.061 55.7233-232.21 95.2076-95.8595 57.2162-166.901 128.83-208.099 207.148-40.0441 76.1221-51.7264 158.895-30.1878 240.841 8.46024 32.1874 21.9874 64.317 40.8638 95.941 54.5682 91.4198 151.485 170.836 275.197 227.695z"
                  fill="#fffffe"
                  fillRule="nonzero"
                ></path>
              </g>
            </svg>{" "}
            SMS
          </button>
        </div>
        {error.length > 0 && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              lineHeight: "normal",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <IoAlertCircleOutline fill="red" style={{ fontSize: "14px" }} />{" "}
            {error}
          </div>
        )}
      </div>
    </>
  );
}
