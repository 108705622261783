"use client";
import { useEffect } from 'react';
import Script from 'next/script';
import { AppleAuth } from '@services/newLogin';
import styles from "../ByEmail/ByEmail.module.scss";
const AppleSignInButton = ({handleRetrieveData, LoadingGoogle, setLoadingGoogle}) => {
  // Function to handle sign-in with Apple
  const signInWithApple = () => {
    if (!window.AppleID) {
      console.error('AppleID SDK is not loaded.');
      return;
    }

    console.log('Signing in with Apple...', window.origin);
    window.AppleID.auth
      .signIn()
      .then(async (response) => {
        console.log('Apple Sign-In Response:', response);
        setLoadingGoogle(true);
        try {
          const {data, status} = await AppleAuth(response.authorization.code, window.origin);
          console.log(data, status);
          if(status === 200) {
            
            const resData = data.data;
            console.log(resData);
            handleRetrieveData(data.data, true);
          }
          
        } catch (error) {
          console.error(error.response.data.message);
          
        }
        setLoadingGoogle(false)
      })
      .catch((error) => {
        console.error('Error during Apple Sign-In:', error);
      });
  };

  const initializeAppleID = () => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: 'tickit.co.login', // Replace with your Bundle ID
        scope: 'name email', // Requested user data
        redirectURI: window.origin, // Replace with your backend endpoint
        state: 'optional-state', // Optional state parameter
        usePopup: true, // Use popup instead of redirect
      });
      console.log('AppleID initialized successfully.');
    } else {
      console.error('AppleID SDK is not available.');
    }
  };

  return (
    <>
      {/* Load the AppleID SDK */}
      <Script
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="afterInteractive"
        onLoad={initializeAppleID}
        onError={() => {
          console.error('Failed to load the AppleID SDK.');
        }}
      />
      <button onClick={ signInWithApple} disabled={LoadingGoogle}>
    {LoadingGoogle ? <div className={styles.loader}></div> : <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.5 16.5C30.5 24.228 24.235 30.5 16.5 30.5C8.765 30.5 2.5 24.228 2.5 16.5C2.5 8.76499 8.765 2.49999 16.5 2.49999C24.235 2.49999 30.5 8.76499 30.5 16.5Z"
              fill="#333333"
            />
            <path
              d="M23.0621 12.9574C22.9857 13.002 21.1671 13.9425 21.1671 16.0279C21.2528 18.4061 23.4621 19.2401 23.5 19.2401C23.4621 19.2847 23.1665 20.3763 22.2907 21.5205C21.5956 22.5062 20.8242 23.5 19.6528 23.5C18.5385 23.5 18.1385 22.8431 16.8528 22.8431C15.472 22.8431 15.0813 23.5 14.0242 23.5C12.8528 23.5 12.0242 22.453 11.2913 21.4766C10.3391 20.1986 9.52978 18.1931 9.50121 16.2675C9.48195 15.2471 9.69189 14.244 10.2248 13.3921C10.977 12.2026 12.3198 11.3952 13.7863 11.3686C14.9099 11.3333 15.9099 12.0875 16.5956 12.0875C17.2528 12.0875 18.4814 11.3686 19.8714 11.3686C20.4714 11.3692 22.0714 11.5376 23.0621 12.9574ZM16.5006 11.1649C16.3006 10.233 16.8528 9.30119 17.3671 8.70677C18.0242 7.98792 19.0621 7.5 19.9571 7.5C20.0143 8.43185 19.6522 9.34575 19.005 10.0114C18.4242 10.7302 17.4242 11.2714 16.5006 11.1649Z"
              fill="white"
            />
          </svg>}
      </button>
    </>
  );
};


export default AppleSignInButton;
