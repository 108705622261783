import React, { useState } from "react";
import { Listbox } from "@headlessui/react";
import styles from "./CountryList.module.css";
import { usePathname, useRouter } from "next/navigation";
import Cookies from "js-cookie";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { countries } from "./countries";
import { getCode } from "country-list";

const CountryList = ({ list }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const pathname = usePathname();

  // Convert country name to flag emoji
  const getFlagEmoji = (countryCode) => {
    if (!countryCode) return "";
    return countryCode
      .toUpperCase()
      .split("")
      .map((char) => String.fromCodePoint(0x1f1e6 - 65 + char.charCodeAt(0)))
      .join("");
  };

  // Return display name and inline flag
  const getDisplayName = (country) => {
    if (country.toLowerCase() === "united arab emirates") return "UAE";
    if (country === "United Kingdom") return "UK";
    if (country === "United States") return "USA";
    return country;
  };

  // Component for the flag emoji
  const CountryFlagEmoji = ({ countryName }) => {
    const countryCode = getCode(countryName); // Get ISO code
    if (!countryCode) return <span>🚩</span>; // Fallback
    return <span style={{ fontSize: "1.5rem" }}>{getFlagEmoji(countryCode)}</span>;
  };

  // Selected country logic
  const currentCountry = decodeURIComponent(pathname.substring(1)) || "United Arab Emirates";

  return (
    <div className={styles.relative}>
      <Listbox
        value={selectedCountry}
        onChange={(country) => {
          setSelectedCountry(country);
          router.push(`/${encodeURIComponent(country)}`);
          Cookies.set("selectedCountry", country);
          setIsOpen(false);
        }}
      >
        {/* Button */}
        <Listbox.Button
          className={styles.countryName}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className={styles.display}>{getDisplayName(currentCountry)}</p>
            <CountryFlagEmoji countryName={currentCountry} />
          </div>
          {isOpen ? (
            <FiChevronUp className={styles.arrow} />
          ) : (
            <FiChevronDown className={styles.arrow} />
          )}
        </Listbox.Button>

        {/* Dropdown Options */}
        <Listbox.Options
          className={`${styles.list} ${isOpen ? styles.open : ""}`}
        >
          {list.map((country, index) => (
            <Listbox.Option
              key={index}
              value={country.countryName}
              className={`${styles.countryNameList} ${
                selectedCountry === country.countryName ? styles.selected : ""
              }`}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <p>{getDisplayName(country.countryName)}</p>
                <CountryFlagEmoji countryName={country.countryName} />
              </div>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default CountryList;
