import { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import Cookies from "js-cookie";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useRouter } from "next/navigation";
import styles from "./LoginDropdown.module.css";
import { logout } from "@services/newLogin";

const LoginDropdown = ({ user, tokens }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const router = useRouter();
  useEffect(()=>{
    console.log(isOpen);
    
  },[isOpen])
  const options = [
    { name: "My Events", action: () => {
      setIsOpen(false);
      router.push("/my-events")} },
    {
      name: "Profile",
      action: () => {
        setIsOpen(false);
        router.push("/profile");
      },
    },
    {
      name: loading ? "loading..." : "Logout",
      action: async () => {
        setLoading(true);
        try {
          const { status } = await logout(tokens.idToken);
                
        if(status === 200) {
          Cookies.remove("user");
          Cookies.remove("tokens");
          Cookies.remove("token");
          window.location.reload();
          setIsOpen(false);
        }
        console.log(status);
        
        } catch (error) {
          console.error("here: ",error);
          if(error.response.status === 401) {
            Cookies.remove("user");
            Cookies.remove("tokens");
            Cookies.remove("token");
            window.location.reload();
            setIsOpen(false);
          }
        }
        setLoading(false);
      },
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <>
      <Listbox
        value={selectedOption}
        onChange={(option) => {
          setSelectedOption(option);
          option.action();
       
        }}
      >
        <Listbox.Button
          onClick={() => setIsOpen(!isOpen)}
          className={styles.logedIn}
        >
          <span>
            {user && user.fname && user.fname.substring(0, 1)}
            {user && user.lastName && user.lastName.substring(0, 1)}
          </span>
        </Listbox.Button>

        <Listbox.Options className={isOpen ? styles.isOpen : styles.isAbso}>
          {options.map((option, index) => (
            <Listbox.Option key={index} value={option} className={styles.li}>
              {option.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </>
  );
};

export default LoginDropdown;
