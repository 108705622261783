"use client";

import React, { useState } from "react";
import styles from "./ByEmail.module.scss";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { GoogleAuth, RequestAuthOTP } from "@services/newLogin";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useGoogleLogin,  } from '@react-oauth/google'; // Import useGoogleLogin
import axios from 'axios';
import AppleSignInButton from "../appleSignIn/appleSignIn";

export default function LoginByEmail({ handleRetrieveData, setStartingAccount }) {
  const [email, setEmail] = useState("");
  const [Loading, setLoading] = useState(false);
  const [LoadingGoogle, setLoadingGoogle] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState("");

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = emailRegex.test(email);

  const dummyData = {
    "nextAction": "VALIDATE-OTP",
    "method": "EMAIL",
    "to": "ahmadalhaish2@gmail.com",
    "userData": {
      "firstName": "ahmad",
      "lastName": "alhaish",
      "uid": "NWdB3s6GyVORkFjj6ZoWYJyVOx33"
    },
    "availablePhoneMethods": {
      "SMS": true,
      "WHATSAPP": true 
    },
    "requestID": "bDKEU70gW7oFQMltxFbK"
  };

  const handleSubmit = async () => {
    setLoading(true);
    // handleRetrieveData(dummyData)
    try {
      const { status, data } = await RequestAuthOTP("EMAIL", email, null);
      if (status === 200) {
        setStartingAccount(email);
        setData(data.data);
        handleRetrieveData(data.data);
      }
      console.log(data, status);
    } catch (error) {
      setError(error.response.data.message);
    }
    setLoading(false);
  };

  // Google Sign-In Success Handler
  const handleGoogleSuccess = async (resData) => {
  console.log(resData);
  setLoadingGoogle(true);
   try {
      const {status, data} = await GoogleAuth(resData.code);
      console.log(status, data);
      if(status === 200)
        handleRetrieveData(data.data, true);
      
   } catch (error) {
    console.log(error);
    
   }
   setLoadingGoogle(false);
  };

  // Google Sign-In Error Handler
  const handleGoogleError = (error) => {
    console.error('Google Sign-In Error:', error);
    setError('Google Sign-In failed. Please try again.');
  };

  // Initialize Google Login
  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
    flow: 'auth-code',
    scope: 'openid email profile',
  });

  return (
    <>
      <div className={styles.input}>
        <input
          type="text"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={styles.submit}>
        <button disabled={!isEmailValid || Loading} onClick={handleSubmit}>
          {Loading ? "Sending OTP..." : "Send OTP"}
        </button>
      </div>
      {error.length > 0 && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            lineHeight: "normal",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            gap: "5px"
          }}
        >
          <IoAlertCircleOutline fill="red" style={{ fontSize: "14px" }} />
          {" "}
          {error}
        </div>
      )}
      <div className={styles.otherWay}>
        <span></span>
        <p>Or log in with</p> <span></span>
      </div>
      <div className={styles.providedWays}>
        <AppleSignInButton  handleRetrieveData={handleRetrieveData} LoadingGoogle={LoadingGoogle} setLoadingGoogle={setLoadingGoogle}/>
        <button onClick={() => login()} disabled={LoadingGoogle}>
          {LoadingGoogle ?  <div className={styles.loader}></div>: <FcGoogle />}
        </button>
      </div>
    </>
  );
}
