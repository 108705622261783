import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './chooseResend.module.scss';
import { ResendAuthOTP } from '@services/newLogin';
import WhatsappIcon from './icons/whatsapp';
import SmsIcon from './icons/sms';
import { IoAlertCircleOutline } from "react-icons/io5";


const ResendWays = ({
  data,
  handleRetrieveData,
  Loading,
  setLoading,
  handleResendBack,
  setOpenResend,
  SuccessResend,
  isRoute
  
}) => {
  const [error, setError] = useState("");

  const handleResend = async (method, selectedPhoneMethod) => {
    if (Loading) return;
    setLoading(true);
    console.log(data);
    const userData = data.userData;
    try {
      // Call your resend OTP API here
      const { status, data: resendData } = await ResendAuthOTP(
        data.requestID,
        method,
        selectedPhoneMethod
      );
      if (status === 200) {
        const editedData = {...resendData.data, userData};
        console.log(editedData);
        
        handleRetrieveData(editedData);
        SuccessResend(resendData.data.resendInMinutes);
        // setOpenResend(false);
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleResendMethod = () => {
    if (data.method === 'PHONE') {
      handleResendBack(true);
    } else {
      handleResendBack(false);
    }
  };

  const handleAlternativeResend = () => {
    handleResend("EMAIL", null);
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={isRoute? `${styles.container} ${styles.route}` : `${styles.container}`}>
        <header className={styles.header}>
        <h3 onClick={(e) => {
          e.preventDefault();
          setOpenResend(false);
        }}>{"<"}</h3> 
          <h2 className={styles.title}>Request a New Code</h2>
        </header>

        {/* Resend via Email */}
        {data.method === 'EMAIL' && (
          <div className={styles.submit}>
            <button
              type="button"
              disabled={Loading}
              onClick={() => handleResend("EMAIL", null)}
            >
              {Loading? "Resending..." : "Email"}
            </button>
          </div>
        )}

        {/* Resend via Whatsapp */}
        {(data.userData || data.method === "PHONE" )&& (
          <div className={styles.submit}>
            <button
              type="button"
              disabled={Loading}
              onClick={() => {
                console.log(data.method);
                
                handleResend("PHONE", 'WHATSAPP');

              }
              }
              className={styles.iconButton}
              aria-label="Resend via Whatsapp"
            >
              <WhatsappIcon /> Whatsapp
            </button>
          </div>
        )}

        {/* Resend via SMS */}
        {(data.userData || data.method === "PHONE" )&& (
          <div className={styles.submit}>
            <button
              type="button"
              disabled={Loading}
              onClick={() => handleResend("PHONE", 'SMS')}
              className={styles.iconButton}
              aria-label="Resend via SMS"
            >
              <SmsIcon /> SMS
            </button>
          </div>
        )}

        {/* Alternative Resend Option */}
        {!data.userData && (
          <div className={styles.verifyInfo}>
            <p className={styles.resend}>
              Or register with{' '}
              <span
                className={styles.activeResend}
                onClick={toggleResendMethod}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') toggleResendMethod();
                }}
              >
                {data.method === 'PHONE' ? 'email' : 'phone'}
              </span>
            </p>
          </div>
        )}

        {/* Alternative Method when using Phone */}
        {data.userData && data.method === 'PHONE' && (
          <div className={styles.verifyInfo}>
            <p className={styles.resend}>
              Or try with{' '}
              <span
                className={styles.activeResend}
                onClick={handleAlternativeResend}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleAlternativeResend();
                }}
              >
                email
              </span>{' '}
              instead
            </p>
          </div>
        )}
      {error.length>0 && <div style={{color:"red", fontSize:"12px", lineHeight:"normal", marginTop:"10px", display:"flex", alignItems:"center", gap:"5px"}}><IoAlertCircleOutline fill="red" style={{fontSize:"14px"}}/>{" "}{error}</div>}
      </div>
    </>
  );
};

ResendWays.propTypes = {
  data: PropTypes.shape({
    method: PropTypes.oneOf(['EMAIL', 'PHONE']).isRequired,
    selectedPhoneMethod: PropTypes.oneOf(['WHATSAPP', 'SMS']),
    requestID: PropTypes.string.isRequired,
    userData: PropTypes.object, // Replace with more specific shape if possible
  }).isRequired,
  handleRetrieveData: PropTypes.func.isRequired,
  Loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  handleResendBack: PropTypes.func.isRequired,
  setOpenResend: PropTypes.func.isRequired,
};

export default ResendWays;
