// app/layout.jsx
'use client';

import "@styles/globals.css";
import QueryProvider from "./QueryProvider";
import ReduxProvider from "./ReduxProvider";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import ExternalBrowser from "@components/redirect";
import TagManager from 'react-gtm-module';
import IntercomWidget from "@components/Intercom/intercom";
import { useUser } from "@hooks/Auth/useUser";
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import config from "@config/config";

const AdjustInitializer = dynamic(() => import("@hooks/useAdjust"), {
  ssr: false,
});

// Replace with your GTM ID
const gtmId = 'GTM-TCGVZNBP';

// Retrieve the Google Client ID from environment variables
const GOOGLE_CLIENT_ID = config.GCLIENTID;

const RootLayout = ({ children }) => {
  const [links, setLinks] = useState(null);
  let { user } = useUser();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinks(window.location);
    }
  }, []);

  useEffect(() => {
    // Initialize Google Tag Manager
    TagManager.initialize({ gtmId });
  }, []);

  return (
    <html lang="en">
      <body>
        {/* GoogleOAuthProvider wraps all other providers to make OAuth context available globally */}
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <ReduxProvider>
            <QueryProvider>
              <AdjustInitializer />
              {links ? (
                <ExternalBrowser child={children} links={links}>
                  {children}
                </ExternalBrowser>
              ) : (
                children
              )}
              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                ></iframe>
              </noscript>
              <IntercomWidget user={user} />
              <GoogleTagManager gtmId={gtmId} />
              <GoogleAnalytics gaId="G-5E07BFZDQN" />
            </QueryProvider>
          </ReduxProvider>
        </GoogleOAuthProvider>
      </body>
    </html>
  );
};

export default RootLayout;
